import * as React from "react";
import { Tabs, Tab, Typography, Box } from "@mui/material";
import { CampaignManager } from "./CampaignManager";
import NewAccountApproval from "./NewAccountApproval";
import { ThemeProvider } from'@mui/material/styles';
import UItheme from "@bamboobox/b2-theme"
import { FUNNEL_REPORT} from './constant';
import {useLocation} from "react-router-dom";
import ARROW_SVG from "@material-ui/icons/ArrowBackIos"

export default function AUTHORIZATION(props) {
  const search = useLocation().search;
  const filter = new URLSearchParams(search).get('filter');
  const icp =  new URLSearchParams(search).get('icp');

  const onBackClick = () => {
      //${FUNNEL_REPORT}
      console.log("filter ",filter)
      
      let goBack=`${FUNNEL_REPORT}filter/${filter}?icp=${icp}`
      console.log("funnerl report", `${FUNNEL_REPORT}filter/${filter}?icp=${icp}`);
          window.location.href = goBack
      
     }

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
    };
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3, width: 1, height: "100%", paddingTop: "0px", paddingBottom: "0px" }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  return (
    <React.Fragment>
     {/* <ThemeProvider theme={UItheme} > */}
    <Box
      sx={{
        flexGrow: 1,
       background: "#FBFBFB",
        display: "flex",
        flexDirection:"column",
        height: "",
        color:"#595959",
        fontSize:"20px",
        fontWeight:700
      }}
    >
      <div style={{display:"flex",justifyContent:"flex-start", padding:"35px 0px 24px 24px"}}>
      <Typography style={{ marginBottom: '8px', paddingTop:"8px", color:"#595959", fontSize:"20px", fontWeight:700, display: "inline-flex", alignItems:"center"}} > 
      {(filter===null || filter===undefined)?<React.Fragment></React.Fragment>:<ARROW_SVG style={{cursor:'pointer'}} onClick={onBackClick} fontSize="small"/>}
        Authorizations
        </Typography>
      </div>
      <div style={{display:"flex"}}>
      <Tabs
        orientation="vertical"
        variant="scrollable"
        value={value}
        onChange={handleChange}
        aria-label="Vertical tabs example"
        sx={{  overflow: "visible", width: "296px", height: "85vh",padding: '0 0 0 0', marginLeft:"",backgroundColor: "#FFFFFF", boxShadow:"0px 0px 16px rgba(0, 0, 0, 0.1)",borderRadius: "0px 24px 24px 0px", }}
        TabIndicatorProps={{style: {background:'#21DA8C'}}}
      >
        <Tab label="CAMPAIGN APPROVAL" {...a11yProps(0)} style={{ lineHeight: '22px', marginTop: '20px', textAlign: "left", alignContent: "flex-start", width: "fit-content",  fontFamily:"'Nunito Sans', 'sans-serif'", fontWeight:700, fontSize:"14px", color:"#595959" }} />
        <Tab label="NEW ACCOUNTS" {...a11yProps(1)} style={{ lineHeight: '22px', marginLeft: '2px', marginRight: '2px', textAlign: "left", alignContent: "flex-start", width: "fit-content",  fontFamily:"'Nunito Sans', 'sans-serif'", fontWeight:700,  fontSize:"14px", color:"#595959"   }} />
        {/* <Tab label="Option 3" {...a11yProps(2)} /> */}
      </Tabs>
      <div className="display_tabs_content" style={{backgroundColor: "#FBFBFB", height: "100vh", width: "100vw"}}>
        <TabPanel value={value} index={0}>
          <CampaignManager />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <NewAccountApproval style={{padding: "0px"}}/>
        </TabPanel>
      </div>
      {/* <TabPanel value={value} index={2}>
        Item Three
      </TabPanel> */}
      </div>
      
    </Box>
    {/* </ThemeProvider> */}
    </React.Fragment>
  );
}
