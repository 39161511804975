import React, { useState, useEffect } from 'react'
import { DataGrid } from '@material-ui/data-grid';
import { Button } from "@material-ui/core";
import moment from "moment";
import "./table.css"
import { HeaderIntent } from './Header';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from "@material-ui/core/Tooltip";
import { IconButton } from "@material-ui/core";
import { useAuthState } from "@bamboobox/b2logincheck";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { MuiThemeProvider } from "@material-ui/core";
import UItheme from "@bamboobox/b2-theme"
import Paper from '@mui/material/Paper';
import Alert from '@mui/material/Alert';
import "./toast.css"
const NewAccountApproval = () => {

  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: false,
  });


  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const intentProvider = 'BOMBORA'
  const [rows, setRows] = useState([])
  const [loading, setLoading] = useState(false)
  const [action, setAction] = useState("")
  const [listOfRowIds, setListOfRowIds] = useState([])
  const[noOfRows, setNoOfRows] = useState(-1)
  //const [disable, setDisable] = useState(false);

  console.log("list of selected row id", listOfRowIds)

  let rowDataArr = []

  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    {
      field: 'company_name',
      headerName: 'Company Name',
      width: 210,
    },
    {
      field: 'company_website',
      headerName: 'Company Website',
      width: 210,
    },
    {
      field: 'company_size_range',
      headerName: 'Company Size (Bombora)',
      width: 200,
      
    },
    {
      field: 'surging_country',
      headerName: 'Country (Bombora)',
      width: 200,
    },
    {
      field: 'company_industry',
      headerName: 'Company Industry (Bombora)',
      width: 200,
    },
    {
      field: 'b2_intent_level',
      headerName: 'Intent Level',
      type: 'string',
      width: 175,
    },
    {
      field: 'intent_creation_date',
      headerName: 'Last Intent Date',
      type: 'string',
      width: 200,
    },
    {
      field: 'topics_data',
      headerName: 'Topics',
      type: 'string',
      width: 300,
    },
    {
      field: '',
      headerName: '',
      width: 100,
      disableColumnMenu: true,
      renderCell: (params) => {
        console.log("params", params)

        return (
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "32px" }}>
            <Tooltip disableFocusListener title="Reject">
              <IconButton aria-label="reject" style={{ padding: "0" }}>
                <CancelIcon
                  style={{
                    color: "red",
                    cursor: "pointer",
                  }}
                  onClick={() => { approveRejectFunctionForSolo("REJECT", [params.row.id]); console.log(params) }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip disableFocusListener title="Approve">
              <IconButton aria-label="Approve" style={{ padding: "0" }}>
                <CheckCircleIcon
                  style={{
                    color: "green",
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => { approveRejectFunctionForSolo("APPROVE", [params.row.id]); console.log(params) }}
                />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    }
  ];

  const getTopicList = (topicsData) => {
    let topicArr = []
    if (topicsData) {
      let arrOfTopic = topicsData.split('""')
      for (let topicStr of arrOfTopic) {
        let topic = topicStr.split('\\"')[1]
        if (topic) {
          topic = topic.substring(0, topic.length - 1)
          topicArr.push(topic)
        }
      }
    }
    return topicArr.join()
  }
  
  const setTableData = () => {
    fetch(
      `${window._env_.CDP_URL}/intent/add/account/getPendingAccount/${intentProvider}/${tenantId}`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    )
      .then(response => response.json())
      .then(data => {
        console.log(`%c${JSON.stringify(Object.values(data))}`, 'background:yellow;')
        setNoOfRows(data.length)
        if (data?.msg === "EMPTY" || Object.values(data).length <= 0) {
          // toast.warn("No pending requests available", {

          //   closeOnClick: true,

          //   autoClose: true,
          //   closeButton: true,
          //   newestOnTop: true
          // });
          setLoading(false)
        } else {
          Object.values(data).forEach(obj => {
            let rowDataObject = {
              id: "",
              company_name: "",
              company_website: "",
              b2_intent_level: "",
              intent_creation_date: "",
              topics_data: "",
              company_industry:"",
              company_size_range:"",
              surging_country:"",
            }


            rowDataObject.id = obj.row_id;
            rowDataObject.company_name = obj.company_name
            rowDataObject.company_website = obj.company_website
            rowDataObject.company_industry = obj.company_industry
            rowDataObject.surging_country = obj.surging_country
            rowDataObject.company_size_range = obj.company_size_range
            rowDataObject.b2_intent_level = obj.b2_intent_level
            rowDataObject.intent_creation_date = moment(obj.intent_creation_date).format('DD-MM-YYYY')
            rowDataObject.topics_data = getTopicList(obj.topics_data_aggr)//JSON.stringify(obj.topics_data)

            rowDataArr.push(rowDataObject)
          })
          setRows(rowDataArr)
          setLoading(false)
          console.log(`%c${JSON.stringify(rowDataArr)}`, 'background:black;color:white')
        }
      }).catch((error) => {
        console.log("error", error)
        toast.error(<Alert severity="error">Error Occuredd while retreiving data</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
      })
  }
  const approveRejectFunction = (action) => {
    console.log(listOfRowIds);
    let reqBodyArr = []

    if (action === "REJECT") {
      listOfRowIds.forEach((rowId) => {
        let actionRequestObj = {
          rowId: "",
          action: "REJECT"
        }
        actionRequestObj.rowId = rowId;
        reqBodyArr.push(actionRequestObj)
      })

    } else {

      listOfRowIds.forEach((rowId) => {
        let actionRequestObj = {
          rowId: "",
          action: "APPROVE"
        }
        actionRequestObj.rowId = rowId;
        reqBodyArr.push(actionRequestObj)
      })

      console.log('background:black;color:white')
    }

    fetch(
      `${window._env_.CDP_URL}/intent/add/account/approve-account-pending/${intentProvider}/${tenantId}`, {
      method: "POST",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(reqBodyArr),
      credentials: "include",
    }
    ).then(response => response.json())
      .then(data => {
        //console.log("approve data", `%c${JSON.stringify(Object.values(data))}`, 'background:yellow;')

        if (data?.msg && (data?.msg || "").trim().toLowerCase() === "success") {
          toast.success(<Alert severity="success">Your request has been processed Successfully</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
          setTableData()
        } else if (data?.msg && (data?.msg || "").trim().toLowerCase().length > 0) {
          toast.error(<Alert severity="error">Some of your requests have not been processed</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
          setTableData()
        } else {
          console.log("error")
          toast.error(<Alert severity="error">Error Occurred while processing your request</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        }





      }).catch(error => {
        toast.error(<Alert severity="error">Error Occurred while processing your request</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });

      })

  }

  const approveRejectFunctionForSolo = (soloAction, rowSelected) => {
    let soloReqBodyObj = [{
      "rowId": rowSelected[0],
      "action": soloAction
    }]

    fetch(
      `${window._env_.CDP_URL}/intent/add/account/approve-account-pending/${intentProvider}/${tenantId}`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(soloReqBodyObj),
        credentials: "include",
      }
    ).then(response => response.json())
      .then(data => {
        console.log("data after click", data)
        console.log("Campaign Manager solo success", rows)

        if (data?.msg && (data?.msg || '').trim().toLowerCase() === "success") {

          toast.success(<Alert severity="success">Your request has been processed Successfully</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
          setTableData()
        } else {
          console.log("Campaign Manager solo else")

          toast.error(<Alert severity="error">Error Occurred while processing your request</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        }
      }).catch(error => {
        console.log("Campaign Manager solo error", error)

        toast.error(<Alert severity="error">Error Occurred while processing your request</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
      })

  }

  useEffect(() => {
    setLoading(true)
    setTableData()
  }, [])

  return (
    <MuiThemeProvider theme={UItheme}>
      <div className="campaign-container">
        <div className="table-attributes">
          <HeaderIntent />
          <div style={{ display: "flex", width: "216px" }}>
            <Button  disabled={Array.isArray(listOfRowIds) && !listOfRowIds.length ? true : false} variant="outlined" onClick={() => approveRejectFunction("REJECT")} style={{ marginLeft: "", textTransform: "none", borderRadius:"16px", backgroundColor:"#FFFFFF",color:listOfRowIds?.length>0? "#21DA8C":"#A0A0A0", borderColor: listOfRowIds?.length>0? "#21DA8C":"#A0A0A0", height:"32px", fontSize:"12px",fontWeight:800 }} >REJECT</Button>
            <Button  disabled={Array.isArray(listOfRowIds) && !listOfRowIds.length ? true : false} variant="contained" onClick={() => approveRejectFunction("APPROVE")} style={{ marginLeft: "16px", textTransform: "none" , borderRadius:"16px", backgroundColor: listOfRowIds?.length>0? "#21DA8C":"#A0A0A0", color:"#FFFFFF", height:"32px",fontSize:"12px",fontWeight:800}}>APPROVE</Button>
          </div>
        </div>
        <Paper id="cmapaign-table" style={{ borderRadius: '18px', padding: `8px 28px 4px`, boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`, background: `#FFFFFF`,backgroundColor: "#FFFFFF",height: 548, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '2em', marginRight: '2em' }}>
          <DataGrid
            rows={rows}
            columns={columns}
            // loading={rows.length !== noOfRows}
            checkboxSelection
            disableSelectionOnClick
            rowsPerPageOptions={[10, 50, 75, 100]}
            // rowHeight={36}
            // headerHeight={36}
            loading={loading}
            onSelectionModelChange={(newSelectionModel) => {
              setListOfRowIds(newSelectionModel);
            }}
            disableColumnSelector={true}
          />
        </Paper>
      </div>
    </MuiThemeProvider>
  );
}

export default NewAccountApproval