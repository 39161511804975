import React from "react";
import Link from '@material-ui/core/Link';
import { Typography } from "@material-ui/core";


const BecomeAnchorTagIfIdPresent = props => {
    // const [isIdPresent, setIdPresent] = useState(false);
    // const [linkToDetailedView, setLinkToDetailedView] = useState("");

    
    let linkToDetailedView = window._env_.LOGIN_URL+"/#/unified-account-profile?params="+escape("AccountDetail/"+props.accountId+"/1");

    return (
    <div id="becomeAnchorTagIfIdPresent">

      <React.Fragment>
        <Typography >
            <Link rel="noreferrer" href={linkToDetailedView} target="_blank" >
            {props.value}
            </Link>
          </Typography>
        {/* <a className="becomeAnchorTagIfIdPresent_anchor" target="_blank" rel="noopener" href={linkToDetailedView}>{props.value}</a> */}
      </React.Fragment>

  </div>
  )
    
};

export default BecomeAnchorTagIfIdPresent;
