import './App.css';
import AUTHORIZATION from "./authorizationMain"
import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import { version } from '../package.json';
import CacheBuster from 'react-cache-buster';
import { useClearCache } from 'react-clear-cache';
import { useEffect } from 'react';



console.log("verison of build", version);
function App(props) {
const { isLatestVersion, emptyCacheStorage } = useClearCache();

useEffect(() => {
  console.log("latest version",isLatestVersion);
  if(!isLatestVersion) {
      // e.preventDefault();
      emptyCacheStorage();
      window.location.reload(true);
        
  }
}, [isLatestVersion])

  return (
    <CacheBuster
        currentVersion={version}
        isEnabled={true} //If false, the library is disabled.
      isVerboseMode={true}>
      <div className="App">

      <Router>
        <Routes>
          <Route path="*" element={<AUTHORIZATION />}></Route>
        </Routes>
      </Router>
    </div>
    </CacheBuster>
  );
}

export default App;