import React from 'react';
import "./Header.css";
import Typography from '@material-ui/core/Typography';

export const HeaderCampaign = () => {

    return (
        <div className="header-container">
            <div className="back-header-container">
                <Typography style={{fontSize:"16px",color:"#595959",fontWeight:700,fontFamily:'Nunito sans'}}>
                    Campaign Approval
                </Typography>
            </div>
        </div>
    )
}

export const HeaderIntent = () => {
    return (
        <div className="header-container">
            <div className="back-header-container">
                <Typography style={{fontSize:"16px",color:"#595959",fontWeight:700,fontFamily:'Nunito sans'}}>
                    New Accounts
                </Typography>
            </div>
        </div>
    )
}
