import React, { useState, useEffect } from 'react'
// import { DataGrid, useGridParamsApi } from '@material-ui/data-grid';
import { Button } from "@material-ui/core";
import { DataGrid } from '@mui/x-data-grid';
import moment from "moment"
import { HeaderCampaign } from './Header';
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from '@material-ui/icons/Cancel';
import Tooltip from "@material-ui/core/Tooltip";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { IconButton } from "@material-ui/core";
import { CDP_URL } from "./constant"
import { useAuthState } from "@bamboobox/b2logincheck";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { MuiThemeProvider } from "@material-ui/core";
import UItheme from "@bamboobox/b2-theme"
import BecomeAnchorTagIfIdPresent from "./BecomeAnchorTagIfIdPresent"
import { PinDropSharp } from '@material-ui/icons';
import SimpleBackdrop from "./SimpleBackdrop";
import { styled } from "@mui/material/styles";
import "./table.css";
import Paper from '@mui/material/Paper';
import UITheme from "@bamboobox/b2-theme"
import Alert from '@mui/material/Alert';
import "./toast.css"
import _ from 'lodash'
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;

export const CampaignManager = () => {

  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: false,
  });


  const userDetails = useAuthState();
  const tenantId = userDetails.user.tenantId;
  const [rows, setRows] = useState([])
  const [action, setAction] = useState("")
  const [listOfRowIds, setListOfRowIds] = useState([])
  const [noOfRows, setNoOfRows] = useState(-1)
  const [loading, setLoading] = useState(false)
  const [activeProgramList, setActiveProgramList] = useState([])
  const [flagForPrograms, setFlagForPrograms] = useState(false)
  const [backdropState, setBackdropState] = useState(false);
  //const [pageSize, setPageSize] = React.useState(25);

  // function customCheckbox(theme) {
  //   return {
  //     "& .MuiCheckbox-root svg": {
  //       width: 16,
  //       height: 16,
  //       backgroundColor: "transparent",
  //       border: `1px solid ${theme.palette.mode === "light" ? "#d9d9d9" : "rgb(67, 67, 67)"
  //         }`,
  //       borderRadius: 2
  //     },
  //     "& .MuiCheckbox-root svg path": {
  //       display: "none"
  //     },
  //     "& .MuiCheckbox-root.Mui-checked:not(.MuiCheckbox-indeterminate) svg": {
  //       backgroundColor: "#1890ff",
  //       borderColor: "#1890ff"
  //     },
  //     "& .MuiCheckbox-root.Mui-checked .MuiIconButton-label:after": {
  //       position: "absolute",
  //       display: "table",
  //       border: "2px solid #fff",
  //       borderTop: 0,
  //       borderLeft: 0,
  //       transform: "rotate(45deg) translate(-50%,-50%)",
  //       opacity: 1,
  //       transition: "all .2s cubic-bezier(.12,.4,.29,1.46) .1s",
  //       content: '""',
  //       top: "50%",
  //       left: "39%",
  //       width: 5.71428571,
  //       height: 9.14285714
  //     },
  //     "& .MuiCheckbox-root.MuiCheckbox-indeterminate .MuiIconButton-label:after": {
  //       width: 8,
  //       height: 8,

  //       transform: "none",
  //       top: "39%",
  //       border: 0
  //     }
  //   };
  // }

  // const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  //   borderRadius: "18px",
  //   paddingTop: "15px",
  //   boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
  //   paddingBottom: "13px",
  //   fontFamily: [

  //   ].join(","),
  //   WebkitFontSmoothing: "auto",
  //   "& .MuiDataGrid-colCellTitle": {
  //     fontSize: "14px"
  //   },
  //   "& .MuiDataGrid-cell MuiDataGrid-cellLeft": {
  //     fontSize: "10px!important",

  //   },
  //   // letterSpacing: "normal",
  //   // "& .MuiDataGrid-columnsContainer": {
  //   //   height: "66px"
  //   //   // backgroundColor: theme.palette.mode === "light" ? "#ffffff" : "#1d1d1d"
  //   // },
  //   // "& .MuiDataGrid-iconSeparator": {
  //   //   display: "none"
  //   // },
  //   // "& .MuiPaginationItem-root": {
  //   //   borderRadius: 0
  //   // },
  //   "& .MuiDataGrid-columnHeader--moving":{
  //     backgroundColor: "transparent",
  //   },

  //   "& .MuiDataGrid-columnHeaderTitleContainer": {
  //     padding: 0,
  //   },

  //   "& svg.MuiDataGrid-root.MuiDataGrid-iconSeparator": {
  //     color: "#ffffff!important",
  //     opacity: 0
  //   },
  //   "& .MuiIconButton-root.Mui-disabled": {
  //     color: "#37CAD3"
  //   },
  //   // ...customCheckbox(theme)
  // }));


  const removeColumns = (rowSelected, errorList, setRows, rows) => {

    try {

      let rowsData = [...rows];

      var erroredRowIdArray = errorList.map(function (el) { return el.rowId; });

      console.log("removeColumns input params ", erroredRowIdArray, rowSelected, rowsData)

      for (let i = 0; i < rowSelected?.length; i++) {

        if (erroredRowIdArray.includes(rowSelected[i])) { console.log("removedColumns in error list"); continue; }
        let indexToBeRemoved = rowsData.findIndex(item => item.id === rowSelected[i])
        console.log("removeColumns index value ", indexToBeRemoved)
        rowsData.splice(indexToBeRemoved, 1)
        console.log("removeColumns rowsData ", rowsData)

      }
      setNoOfRows(rowsData.length)
      setRows(rowsData)
    } catch (e) {
      console.log(e)
      toast.error(<Alert severity="error">Error Occured. Please refresh the page</Alert>, {
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
      });
    }
  }

  let rowDataArr = []



  const columns = [
    { field: 'id', headerName: 'ID', hide: true },
    { field: 'accountId', headerName: 'Account Id', hide: true, disableColumnMenu: true, },
    { field: 'contactId', headerName: 'Contact Id', hide: true, disableColumnMenu: true, },
    {
      field: '',
      headerName: 'Action',
      width: 102,
      disableColumnMenu: true,
      disableColumnReorder: true,
      sortable: false,
      renderCell: (params) => {


        return (
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", height: "32px" }}>
            <Tooltip disableFocusListener title="Reject">
              <IconButton aria-label="reject" style={{ padding: "0" }}>
                <CancelIcon
                  style={{
                    color: "#FF8585",
                    cursor: "pointer",
                  }}
                  onClick={() => { approveRejectFunctionForSolo("REJECT", [params.row.id]); console.log(params) }}
                />
              </IconButton>
            </Tooltip>

            <Tooltip disableFocusListener title="Approve">
              <IconButton aria-label="Approve" style={{ padding: "0" }}>
                <CheckCircleIcon
                  style={{
                    color: "#21DA8C",
                    marginLeft: 10,
                    cursor: "pointer",
                  }}
                  onClick={() => { approveRejectFunctionForSolo("APPROVE", [params.row.id]); console.log(params) }}
                />
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    },
    {
      field: 'contactName',
      headerName: 'Contact Name',
      width: 200,
    },
    {
      field: 'persona',
      headerName: 'Persona',
      width: 140,
    },
    {
      field: 'accountName',
      headerName: 'Account Name',
      type: 'string',
      width: 200,
      renderCell: (params) => {
        return (
          <BecomeAnchorTagIfIdPresent accountId={params.row.accountId} value={params.row.accountName} />
        );
      }
    },
    {
      field: 'accountGeography',
      headerName: 'Geography',
      type: 'string',
      width: 150,
    },
    {
      field: 'campaignName',
      headerName: 'Campaign Name',
      type: 'string',
      width: 200,
    },
    {
      field: 'removeAdd',
      headerName: 'Activity Type',
      type: 'string',
      width: 170,
    },
    {
      field: 'comment',
      headerName: 'Comments',
      type: 'string',
      width: 300,
    },
    {
      field: 'requestDate',
      headerName: 'Request Date',
      type: 'string',
      width: 170,
    }
  ];

  const setTableData = () => {

    fetch(
      `${CDP_URL}/campaignmanager/get/all/${tenantId}`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    )
      .then(response => response.json())
      .then(data => {
        //console.log(`%c${JSON.stringify(Object.values(data))}`, 'background:yellow;')
        setNoOfRows(data.length)
        if (data?.msg === "EMPTY" || Object.values(data).length <= 0) {
          // toast.warn("No pending requests available", {

          //   closeOnClick: true,

          //   autoClose: true,
          //   closeButton: true,
          //   newestOnTop: true
          // });
          setLoading(false)
        } else {

          Object.values(data).forEach(obj => {
            if (activeProgramList.includes(obj.programName)) {
              console.log("Program is active", obj.programName)
              let rowDataObject = {
                id: "",
                accountId: "",
                contactId: "",
                contactName: "",
                persona: "",
                accountName: "",
                accountGeography: "",
                campaignName: "",
                removeAdd: "",
                comment: "",
                requestDate: ""
              }
              rowDataObject.id = obj.rowId;
              rowDataObject.accountId = obj.accountId
              rowDataObject.contactId = obj.contactId
              rowDataObject.contactName = (obj.contactFirstName || '') + " " + (obj.contactLastName || '')
              rowDataObject.persona = obj.persona
              rowDataObject.accountName = obj.accountName
              rowDataObject.accountGeography = obj.accountGeography
              rowDataObject.campaignName = obj.programName
              rowDataObject.removeAdd = obj.removeAdd
              rowDataObject.comment = obj.comment
              rowDataObject.requestDate = moment(obj.requestSentOnDate).format('DD-MM-YYYY')

              rowDataArr.push(rowDataObject)
            }
          })
          setRows(rowDataArr)
          setLoading(false)
          //console.log(`%c${JSON.stringify(rowDataArr)}`, 'background:black;color:white')
        }
      }).catch(error => {

        toast.error(<Alert severity="error">Error Occuredd while retreiving data</Alert>, {
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
      })

  }

  async function setProgramList() {
    console.log("Inside setProgramList")
    fetch(`${CDP_URL}/program/get-all-active-programs?tenantId=${tenantId}`,
      {
        method: "GET",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        credentials: "include",
      }).then(response => response.json())
      .then(resp => {
        if (resp.success) {
          let activeProgram = resp.data.programs.map(obj => obj.programName)

          console.log("Active Program List", activeProgram)

          if (activeProgram.length > 0) {
            setActiveProgramList(activeProgram)
            setFlagForPrograms(true)
          }
          console.log("final active program list", activeProgramList)
        }
      })

  }

  const approveRejectFunction = async (action) => {
    console.log(listOfRowIds);
    let reqBodyArr = []
    setBackdropState(true)
    if (action === "REJECT") {
      listOfRowIds.forEach((rowId) => {
        let actionRequestObj = {
          rowId: "",
          action: "REJECT"
        }
        actionRequestObj.rowId = rowId;
        reqBodyArr.push(actionRequestObj)
      })

    } else {

      listOfRowIds.forEach((rowId) => {
        let actionRequestObj = {
          rowId: "",
          action: "APPROVE"
        }
        actionRequestObj.rowId = rowId;
        reqBodyArr.push(actionRequestObj)
      })
      console.log(reqBodyArr)

      //console.log(`%c${JSON.stringify(reqBodyArr)}`, 'background:black;color:white')
    }
    const actionBatch = _.chunk(reqBodyArr, 9000)
    for (const [index, batch] of actionBatch.entries()) {
      await fetch(
        `${CDP_URL}/campaignmanager/take/action/${tenantId}`,
        {
          method: "POST",
          headers: {
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
          body: JSON.stringify(batch),
          credentials: "include",
        }
      ).then(response => response.json())
        .then(data => {
          //console.log(`%c${JSON.stringify(Object.values(data))}`, 'background:yellow;')

          if (data?.msg && (data?.msg || "").trim().toLowerCase() === "success") {
            setBackdropState(false)
            if (index === actionBatch?.length - 1) {
              toast.success(<Alert severity="success">Your request has been processed Successfully</Alert>, {
                closeOnClick: true,
                autoClose: true,
                closeButton: true,
                newestOnTop: true
              });
            }
            removeColumns(listOfRowIds, (data.errors || []), setRows, rows)
          } else if (data?.msg && data?.msg === "Some Requests Not found") {
            setBackdropState(false)
            if (index === actionBatch?.length - 1) {
              toast.error(<Alert severity="error">Some of your requests do not have LeadId</Alert>, {
                closeOnClick: true,
                autoClose: true,
                closeButton: true,
                newestOnTop: true
              });
            }
          } else if (data?.msg && (data?.msg || "").trim().toLowerCase().length > 0) {
            setBackdropState(false)
            if (index === actionBatch?.length - 1) {
              toast.error(<Alert severity="error">Some of your requests have not been processed</Alert>, {
                closeOnClick: true,
                autoClose: true,
                closeButton: true,
                newestOnTop: true
              });
            }
            removeColumns(listOfRowIds, (data.errors || []), setRows, rows)
          } else {
            setBackdropState(false)
            if (index === actionBatch?.length - 1) {
              toast.error(<Alert severity="error">Error Occurred while processing your request</Alert>, {
                closeOnClick: true,
                autoClose: true,
                closeButton: true,
                newestOnTop: true
              });
            }
          }

        }).catch(error => {
          setBackdropState(false)
          toast.error(<Alert severity="error">Error Occurred while processing your request</Alert>, {
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });

        })
    }


  }

  const approveRejectFunctionForSolo = (soloAction, rowSelected) => {
    console.log(`%c${JSON.stringify(soloAction)}`, 'background:black;color:white')
    console.log(`%c${JSON.stringify(rowSelected)}`, 'background:black;color:white')
    setBackdropState(true)
    let soloReqBodyObj = [{
      "rowId": rowSelected[0],
      "action": soloAction
    }]
    console.log(`%c${JSON.stringify(soloReqBodyObj)}`, 'background:black;color:white')
    fetch(
      `${CDP_URL}/campaignmanager/take/action/${tenantId}`,
      {
        method: "POST",
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(soloReqBodyObj),
        credentials: "include",
      }
    ).then(response => response.json())
      .then(data => {
        console.log("Campaign Manager solo success", rows)
        if (data?.msg && (data?.msg || '').trim().toLowerCase() === "success") {

          setBackdropState(false)
          toast.success(<Alert severity="success">Your request has been processed Successfully</Alert>, {
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });

          removeColumns(rowSelected, [], setRows, rows)
        } else if (data?.msg && data?.msg === "Some Requests Not found") {
          setBackdropState(false)
          toast.error(<Alert severity="error">Some of your requests do not have LeadId</Alert>, {
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        } else {
          console.log("Campaign Manager solo else")
          setBackdropState(false)
          toast.error(<Alert severity="error">Error Occurred while processing your request</Alert>, {
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        }
      }).catch(error => {
        console.log("Campaign Manager solo error", error)
        setBackdropState(false)

        toast.error(<Alert severity="error">Error Occurred while processing your request</Alert>, {
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
      })

  }

  // useEffect(() => {
  //   if (soloAction !== "")
  //     approveRejectFunctionForSolo()
  // }, [rowSelected])

  useEffect(() => {
    setLoading(true)

    if (!flagForPrograms) {
      setProgramList()
    } else {
      setTableData()
    }
  }, [flagForPrograms])

  // useEffect(() => {
  //   if (action !== "")
  //     approveRejectFunction()
  // }, [action])

  return (

    <MuiThemeProvider theme={UITheme}>
      <div className="campaign-container">
        <div className="table-attributes">
          <HeaderCampaign />
          <div style={{ display: "flex", width: "216px" }}>
            <Button disabled={Array.isArray(listOfRowIds) && !listOfRowIds.length ? true : false} variant="outlined" onClick={() => approveRejectFunction("REJECT")} style={{ marginRight: "", textTransform: "none", borderRadius: "16px", backgroundColor: "#FFFFFF", color: listOfRowIds?.length > 0 ? "#21DA8C" : "#A0A0A0", borderColor: listOfRowIds?.length > 0 ? "#21DA8C" : "#A0A0A0", height: "32px", fontSize: "12px", fontWeight: 800 }}>REJECT</Button>
            <Button disabled={Array.isArray(listOfRowIds) && !listOfRowIds.length ? true : false} variant="contained" onClick={() => approveRejectFunction("APPROVE")} style={{ marginLeft: "16px", textTransform: "none", borderRadius: "16px", backgroundColor: listOfRowIds?.length > 0 ? "#21DA8C" : "#A0A0A0", color: "#FFFFFF", height: "32px", fontSize: "12px", fontWeight: 800 }}>APPROVE</Button>
          </div>
        </div>

        <Paper id="cmapaign-table" style={{ borderRadius: '18px', padding: `8px 28px 4px`, boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`, background: `#FFFFFF`, backgroundColor: "#FFFFFF", height: 548, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: '2em', marginRight: '2em' }}>

          <DataGrid
            rows={rows}
            columns={columns}
            //  loading={rows.length !== noOfRows}
            checkboxSelection
            disableSelectionOnClick
            rowsPerPageOptions={[10, 50, 75, 100]}
            loading={loading}
            onSelectionModelChange={(newSelectionModel) => {
              console.log(newSelectionModel);
              setListOfRowIds(newSelectionModel);
            }}

            disableColumnSelector={true}
          />


        </Paper>
      </div>
    </MuiThemeProvider>


  );
}



