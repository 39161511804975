export const UNIFIED_URL = window._env_.UNIFIED_URL;
export const ATTRIBUTE_URL = window._env_.ATTRIBUTE_URL;
export const FILTER_URL = window._env_.FILTER_URL;
export const B2PROSPECT_URL = window._env_.B2PROSPECT_URL;
export const CDP_URL = window._env_.CDP_URL;
export const LOGIN_URL = window._env_.LOGIN_URL;
export const AUTH_URL = window._env_.AUTH_URL;
export const ICP_URL = window._env_.ICP_URL;
export const LICENSE_KEY = window._env_.LICENSE_KEY;
export const LOGIN_API_URL = window._env_.LOGIN_API_URL;
export const FUNNEL_REPORT = window._env_.FUNNEL_REPORT;
